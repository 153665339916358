import { ComponentType, useEffect } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { useRouter, inferInitialRouteFromPath } from "framer"
import { auth } from "https://cdn.framerauth.com/scripts/framerauth-sdk@beta.js"

const useStore = createStore({
    user: null,
    isAuthenticated: false,
    isLoaded: false,
})

export function withAuth(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        useEffect(() => {
            if (!store.isAuthenticated) {
                auth.getUser({ cache: false }).then(({ data, error }) => {
                    const { user } = data
                    if (user) {
                        setStore({
                            user: user,
                            isAuthenticated: true,
                            isLoaded: true,
                        })
                    } else {
                        setStore({
                            isLoaded: true,
                        })
                    }
                })
            }
        }, [])

        return <Component {...props} store={store} />
    }
}

export function withStore(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()
        return <Component {...props} store={store} />
    }
}

export function withSignOut(Component): ComponentType {
    const handleClick = () => {
        auth.signOut("/")
    }

    return (props) => {
        const [store, setStore] = useStore()

        if (!store.user) return null
        return <Component {...props} onClick={handleClick} />
    }
}

export function withEmail(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        if (!store.user) return null
        return <Component {...props} text={store.user.email} />
    }
}

export function withFirstName(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        if (!store.user) return null
        return <Component {...props} text={store.user.first_name} />
    }
}

export function withLoggedIn(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        if (!store.user) return null
        return <Component {...props} />
    }
}

export function withLoggedOut(Component): ComponentType {
    return (props) => {
        const [store] = useStore()

        if (store.user) return null

        let className = props.className || ""
        if (!store.isLoaded) {
            className += " fa-cloak"
        }
        return <Component {...props} className={className.trim()} />
    }
}

export function withVariant(Component): ComponentType {
    return (props) => {
        const [store] = useStore()
        const variant = store.user ? null : props?.variant
        return <Component {...props} variant={variant} />
    }
}

export function withIsLessonComplete(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        // Do not display the component if the user is logged out
        if (!store.user) return null

        const key = `course:status:${props?.slug}`
        const isCompleted = store.user?.data?.[key] === "completed"
        if (!isCompleted) return null

        return <Component {...props} />
    }
}

export function withActionCompleteLesson(Component): ComponentType {
    return (props) => {
        const router = useRouter()
        const [store, setStore] = useStore()

        const key = `course:status:${props?.slug}`
        let isCompleted = store.user?.data?.[key] === "completed"

        // Do not display the component if the user is logged out
        if (!store.user) return null

        // Define an async function handleClick to handle the click event.
        const handleClick = async (event: MouseEvent) => {
            try {
                // If the lesson has been completed exit the function
                if (isCompleted) return

                // Update the components local state (for instant UI update)
                isCompleted = true

                // Call the FramerAuth API to update the user data, marking the course as completed.
                const { data, error } = await auth.patchUserData({
                    [key]: "completed",
                })

                // If there is an error throw it.
                if (error) {
                    throw new Error("Error updating course status.")
                }

                // If there is no error, update the user data in the store with the updated data.
                const updatedUser = { ...store.user, data }
                setStore({ user: updatedUser })

                // If there is a link, redirect the user.
                if (props?.link) {
                    const [path, hash] = props.link.split("#")

                    const { routeId, pathVariables } =
                        inferInitialRouteFromPath(router.routes, path)
                    if (routeId) {
                        router.navigate(routeId, hash, pathVariables)
                    }
                }
            } catch (error) {
                console.error(error)
                isCompleted = false
            }
        }

        // Set the variant based on the completed state
        const variant = isCompleted ? props?.variant : null

        // Render the original component with the modified props and onClick handler.
        return <Component {...props} variant={variant} onClick={handleClick} />
    }
}
export function withPlanStandard(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        // Enter your LemonSqueezy Product Variant Id
        const licenseVariantId = "152795" // Standard

        if (
            !store.user ||
            !store.user.licenses.some((l) => l.variant_id === licenseVariantId)
        )
            return null
        return <Component {...props} />
    }
}
